var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1000px", height: "600px" },
    },
    [
      _c("form", { attrs: { id: "frm_eman" } }, [
        _c(
          "button",
          {
            staticClass: "layer_close",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" close ")]
        ),
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX050P060.001"))),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(0),
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon plus",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.addEmanInfo()
                          },
                        },
                      },
                      [_vm._v(" plus ")]
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.002")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.003")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.004")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.268")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.006")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.007")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.008")))]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _vm._l(_vm.params.emanList, function (eman, idx) {
                    return [
                      _c("tr", { key: "tr_emanInfo_" + idx }, [
                        _c("th", { attrs: { rowspan: "2" } }, [
                          _c(
                            "button",
                            {
                              class: [
                                "tbl_icon",
                                "minus",
                                _vm.params.emanList.length === 1
                                  ? "disabled"
                                  : "",
                              ],
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeEmanInfo(idx)
                                },
                              },
                            },
                            [_vm._v(" minus ")]
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(eman.seq))]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: { id: "qty_" + idx, "is-comma": true },
                              on: {
                                input: function ($event) {
                                  _vm.fnValidationQuantity(eman.seq)
                                  _vm.fnSubTotal()
                                },
                              },
                              model: {
                                value: eman.pkgQty,
                                callback: function ($$v) {
                                  _vm.$set(eman, "pkgQty", $$v)
                                },
                                expression: "eman.pkgQty",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: eman.pkgCd,
                                  expression: "eman.pkgCd",
                                },
                              ],
                              attrs: { name: "", id: "pkgCd_" + idx },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      eman,
                                      "pkgCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.fnValidationPkgCd(eman.seq)
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX050P060.009"))),
                              ]),
                              _vm._l(_vm.pkgCdList, function (commonCode) {
                                return _c(
                                  "option",
                                  {
                                    key: "01130_" + commonCode.cd,
                                    domProps: { value: commonCode.cd },
                                  },
                                  [_vm._v(" " + _vm._s(commonCode.cdNm) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "wt_" + idx,
                                "is-comma": true,
                                point: 3,
                                "digit-max": 10,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.fnValidationWeight(eman.seq)
                                  _vm.fnSubTotal()
                                },
                              },
                              model: {
                                value: eman.netWt,
                                callback: function ($$v) {
                                  _vm.$set(eman, "netWt", $$v)
                                },
                                expression: "eman.netWt",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "cbm_" + idx,
                                "is-comma": true,
                                point: 4,
                                "digit-max": 10,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.fnValidationCBM(eman.seq)
                                  _vm.fnSubTotal()
                                },
                              },
                              model: {
                                value: eman.netCbm,
                                callback: function ($$v) {
                                  _vm.$set(eman, "netCbm", $$v)
                                },
                                expression: "eman.netCbm",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: eman.imdunNo,
                                expression: "eman.imdunNo",
                              },
                            ],
                            attrs: { type: "text", maxlength: "4" },
                            domProps: { value: eman.imdunNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(eman, "imdunNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", { attrs: { rowspan: "2" } }, [
                          _c(
                            "div",
                            { attrs: { id: "cntr_no_" + idx } },
                            [
                              _vm._l(_vm.cntrList, function (cntr, idx2) {
                                return [
                                  _c("input", {
                                    key:
                                      cntr.cntrNo +
                                      "_no_" +
                                      eman.seq +
                                      "_" +
                                      idx2,
                                    attrs: {
                                      type: "checkbox",
                                      name: "conno_" + eman.seq,
                                      id: "conno_" + eman.seq + "_" + idx2,
                                    },
                                    domProps: {
                                      checked: eman.cntrNo.includes(
                                        cntr.cntrNo
                                      ),
                                      value: cntr.cntrNo,
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectCntrNo(eman.seq)
                                        _vm.fnValidationContainer(eman.seq)
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      key:
                                        cntr.cntrNo +
                                        "_lbl_" +
                                        eman.seq +
                                        "_" +
                                        idx2,
                                      attrs: {
                                        for: "conno_" + eman.seq + "_" + idx2,
                                      },
                                    },
                                    [_vm._v(_vm._s(cntr.cntrNo))]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("tr", { key: "tr_emanInfo_cmdt_" + idx }, [
                        _c(
                          "th",
                          {
                            staticClass: "border_left",
                            attrs: { colspan: "2" },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.010")))]
                        ),
                        _c("td", { attrs: { colspan: "4" } }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: eman.gdsDsc,
                                expression: "eman.gdsDsc",
                              },
                            ],
                            staticStyle: { "min-height": "50px" },
                            attrs: { id: "cmdt_" + idx, maxlength: "500" },
                            domProps: { value: eman.gdsDsc },
                            on: {
                              keyup: function ($event) {
                                return _vm.fnValidationCmdt(eman.seq)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(eman, "gdsDsc", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c("table", { staticClass: "tbl_col mt10" }, [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _c("th", { attrs: { rowspan: "2" } }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX050P060.011"))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.012")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.013")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.014")))]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "border_left" }, [
                    _vm._v(_vm._s(_vm.params.sumQty)),
                  ]),
                  _c("td", [_vm._v(_vm._s(_vm.params.grsWt))]),
                  _c("td", [_vm._v(_vm._s(_vm.params.grsCbm))]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.fnEmanSave()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.015")))]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX050P060.016")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "18%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "16%" } }),
      _c("col", { attrs: { width: "28%" } }),
      _c("col", { attrs: { width: "28%" } }),
      _c("col", { attrs: { width: "28%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }