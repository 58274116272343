import Send from '../../trans.client.js'

export default {
  // SHA M/F Data 정보 가져오기
  getShaMfDataList (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/sha-mf-data',
      method: 'get',
      params: params
    })
  },
  // SHA M/F Data 정보 저장
  popEmanUpload (params) {
    return Send({
      url: '/trans/bl/' + params.blNo + '/sha-mf-data',
      method: 'post',
      data: params
    })
  }
}
